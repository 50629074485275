const OSS = require('ali-oss');
const path = require("path");
import store from '@/store';
import {
  get
} from "@get/http";
let OSSClient = false;


const put = async (client, file) => {

  try {
    console.log('???');
    return client.multipartUpload(`/home/store/chat/${Math.round(new Date() / 1000)}__${file?.file?.file?.name || file?.file?.name}`, file?.file?.file || file?.file).then(result => {
      return new Promise((ro) => {
        ro(
          result
        )
      })
    })
  } catch (e) {
    console.error('error: %j', e);
  }
}



export const upload = async (file) => {
  // console.log(file);
  let ossData = store.state.common.OSS;
  if (ossData?.Expiration) {
    //是否存在OSS
    let lastTime = new Date(ossData?.Expiration).getTime(),
      nowTime = parseInt(new Date().getTime());
    if (nowTime > lastTime) {
      //过期就重新获取拼争
      ossData = await getOss(file);
    }
    if (!OSSClient) {
      OSSClient = new OSS({
        // yourregion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        // region: 'oss-cn-shenzhen',
        // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
        accessKeyId: ossData.AccessKeyId,
        accessKeySecret: ossData.AccessKeySecret,
        // 填写Bucket名称。
        bucket: ossData.bucket,
        cname: ossData.cname,
        stsToken: ossData.SecurityToken,
        endpoint: ossData.endpoint
      });
    }
    const client = OSSClient
    return await put(client, file);
  } else {
    getOss(file);
  }
}



export const getOss = async () => {
  /***
   * @method getOss 获取阿里云秘钥
   * 
   */
  if (!store.state.common.OSS?.Expiration) {
    let data = await get(`${process.env.VUE_APP_CHAT_PORT}/getSts`, {}, {
      result: true,
      resultKey: 'code',
      toast: false,
    });
    store.dispatch("doSetOss", data);
  }
}