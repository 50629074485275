<template>
  <div id="create_loading">正在生成中({{ parseInt(num) }}%)....</div>
</template>

<script>
export default {
  props: {
    num: {},
  },
  data() {
    return {};
  },
  watch: {
    // num() {
    //   clearInterval(this.timer);
    //   this.timer = setInterval(() => {
    //     if (this.timeNum < this.num) {
    //       this.timeNum += 2;
    //     } else {
    //       this.timeNum = this.num;
    //       clearInterval(this.timer);
    //     }
    //   }, 1);
    // },
  },
};
</script>

<style lang="scss" scoped>
#create_loading {
  width: 100%;
  height: 100%;
  background: #e5e5e5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  color: #000;
  font-size: 18px;
  text-align: center;
  padding-top: 200px;
}
</style>
